import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import ContactForm from 'components/contact-form';

import MailIcon from 'images/icons/mail.inline.svg';
import PhoneIcon from 'images/icons/phone.inline.svg';
//import AddressIcon from 'images/icons/address.svg';
import paperPlaneImage from 'images/contact/PaperPlan.png';

import './styles.scss';

const Contact = () =>{
  const intl = useIntl();
  return (
  <div className="contact">
    <div className="container">
      <div className="row">
        <div className="col col-12 col-md-5">
          <h1 className="contact__title">
            <FormattedMessage id="contacts.header.title" />
          </h1>
          <p className="contact__text">
            <FormattedMessage id="contacts.header.subtitle" />
          </p>
          <div className="contact__info-container">
            <div className="contact__info">
              <p className="contact__info-title">
                <FormattedMessage id="contacts.header.email.label" />
              </p>
              <span
                className="contact__info-text"
              >
                  <MailIcon className="contact__info-icon" />
                  <FormattedMessage id="contacts.header.email.text1" />
                  @
                  <FormattedMessage id="contacts.header.email.text2" />
              </span>
            </div>
            <div className="contact__info">
              <p className="contact__info-title">
                <FormattedMessage id="contacts.header.phone.label" />
              </p>
              <a
                href={`tel: ${intl.formatMessage({ id: 'contacts.header.phone.text' })}`}
                className="contact__info-text"
              >
                <PhoneIcon className="contact__info-icon" />
                  <FormattedMessage id="contacts.header.phone.text" />
              </a>

              <i className="contact__text__description">
                <FormattedMessage id="contacts.header.phone.description" />
              </i>
            </div>
            <div className="contact__info">
              <p className="contact__info-title">
                <FormattedMessage id="contacts.header.address.label" />
              </p>
              <span
                className="contact__info-text"
              >
                  <svg class="contact__info-icon" width="20" height="21" viewBox="0 0 20 21" fill="none">
                    <path d="M10 10.7136H15L17 12.2379L15 13.7623H10M10 17.8271V2.58386V17.8271ZM6 17.8271H14H6ZM10 4.6163H15L17 6.14063L15 7.66496H10V4.6163ZM10 7.66496H5L3 9.18929L5 10.7136H10V7.66496Z" stroke="#414152" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  
                  IPN Incubadora<br/>
                  Rua Pedro Nunes<br/>
                  3030-199 Coimbra<br/>
                  Portugal
              </span>
            </div>
            
          </div>
          <img
            src={paperPlaneImage}
            alt="Paper Planes"
            className="contact__image"
          />
        </div>
        <div className="col col-12 col-md-6 offset-md-1 col-lg-5 offset-lg-2">
          <div className="contact__form" >
            <a name="cform">&nbsp;</a>
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Contact;
