import React from 'react';

import Link from 'utils/localized-link';

import Banner from 'components/sections/banner';
import Contact from 'components/sections/contact';
import Layout from 'components/layout';
import SEO from 'components/seo';

// Hidden for now:
// import MailIcon from 'images/icons/mail.inline.svg';
// import PhoneIcon from '@youship/assets/images/icons/phone.svg';


const ContactUsPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO title="contacts.title" />
    <Contact />
  </Layout>
);

export default ContactUsPage;
